import React, { Component } from "react";
import { Form, Row, Col, Alert } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { postApi } from "../../../services/api/requestApi";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import moment from "moment-timezone";
import { PROJECT_IDS, REGIONS } from "../../../env";
import LoadingIcon from "../../../components/atoms/LoadingIcon";
import CreatableSelect from "react-select/creatable";

export default class ICAIScript extends Component {
  shapefile = "";
  shapefile_shx = "";
  pkl_file = "";

  constructor(props) {
    super(props);
    this.state = {
      alias: "",
      region: "US",
      input_image_collection: "",
      output_image_collection: "",
      execution_times: {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
        timezone: "UTC",
      },
      working_directory: "",
      script_name: "img_predict_opt.py",
      input_project_id: "",
      output_project_id: "",
      edit: props.edit,
      showModal: props.showModal,
      setShowModal: props.setShowModal,
      reload: props.reload,
      loading: this.props.edit,
      error: "",
      selected_aois: [],
      IC_path_options: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = async () => {
    let response = await postApi("/admin/scripts/details", {
      id: "",
    });
    if (response.success) {
      this.setState({ IC_path_options: response.data.IC_options });
    }

    if (this.props.edit) {
      let response = await postApi("/admin/icscripts/get", {
        id: this.props.currentElem.id,
        type: "AI",
      });
      if (response.success) {
        this.setState({
          ...response.data,
          loading: false,
        });
      }
    }
  };

  handleClose = () => {
    this.state.setShowModal(
      this.state.edit ? "EditICAIScript" : "ICAIScript",
      false
    );
  };

  handleFileChange(e, file) {
    let reader = new FileReader();
    reader.onload = function (e) {
      this[file] = e.target.result.split("base64,")[1];
    }.bind(this);
    reader.readAsDataURL(e.target.files[0]);
  }

  handleSubmit = async () => {
    let edit = this.props.edit;
    let url = edit ? "/admin/icscripts/update" : "/admin/icscripts/create";
    let body = {
      id: this.props.edit ? this.props.currentElem.id : "",
      alias: this.state.alias,
      execution_times: this.state.execution_times,
      script_name: this.state.script_name,
      input_image_collection: this.state.input_image_collection.label,
      output_image_collection: this.state.output_image_collection,
      pkl_file: this.pkl_file,
      shapefile: this.shapefile,
      shapefile_shx: this.shapefile_shx,
      region: this.state.region,
      type: "AI",
      selected_aois: this.state.selected_aois.map((aoi) => {
        return aoi.value;
      }),
      input_project_id: this.state.input_project_id,
      output_project_id: this.state.output_project_id,
    };
    let response = await postApi(url, body);
    if (response.success) {
      this.handleClose();
      this.state.reload();
    } else {
      this.setState({
        error: "Upload failed! Make sure your input is valid and try again.",
      });
    }
  };

  handleChange(e, type, fieldName = undefined) {
    switch (type) {
      case "executionTime":
        let execution_times = this.state.execution_times;
        if (Array.isArray(e)) {
          execution_times[fieldName] = e.map((time) => {
            return time.value;
          });

          this.setState({ execution_times });
        } else {
          let daysToIterate = [fieldName];
          if (fieldName === "every_day") {
            let { timezone, ...exec_times } = this.state.execution_times;
            daysToIterate = Object.keys(exec_times);
          }
          for (let day of daysToIterate) {
            if (!execution_times[day].includes(e.format("H:mm"))) {
              execution_times[day] = [
                ...execution_times[day],
                e.format("H:mm"),
              ];
            }
          }
        }
        this.setState({
          execution_times,
        });
        break;
      default:
        this.setState({
          [type]: e.target.value,
        });
        break;
    }
  }

  renderDays() {
    let days = [];
    let { timezone, ...exec_times } = this.state.execution_times;
    for (let day of [...Object.keys(exec_times), "every_day"]) {
      let formattedDay = (day.charAt(0).toUpperCase() + day.slice(1)).replace(
        "_",
        " "
      );
      days.push(
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>{formattedDay}</Form.Label>
          </Form.Group>
          <Form.Group as={Col}>
            <TimePicker
              defaultValue={moment().minute(0).second(0)}
              showSecond={false}
              minuteStep={15}
              onChange={(e) => {
                this.handleChange(e, "executionTime", day);
              }}
            />
          </Form.Group>
          {!(day === "every_day") ? (
            <Form.Group as={Col}>
              <Select
                isMulti
                value={this.state.execution_times[day].sort().map((time) => {
                  return { label: time, value: time };
                })}
                options={this.state.execution_times[day].map((time) => {
                  return {
                    label: time,
                    value: time,
                    isDisabled: true,
                  };
                })}
                onChange={(e) => this.handleChange(e, "executionTime", day)}
                isDisabled={this.state.execution_times[day].size === 0}
              />
            </Form.Group>
          ) : (
            <Form.Group as={Col}>
              <p> </p>
            </Form.Group>
          )}
        </Row>
      );
    }

    return days;
  }

  renderAois() {
    let options = [];
    for (let index in this.props.availableAois) {
      for (let id in this.props.availableAois[index]) {
        let aoi = this.props.availableAois[index][id];
        options.push({
          value: aoi.aoi_name,
          label: aoi.aoi_name,
          disabled: !aoi.has_polygons,
        });
      }
    }
    return (
      <Row className="mb-3">
        <Form.Group as={Col} className="4">
          <Form.Label>Available Areas of Interest</Form.Label>
          <Select
            id="selected-aois"
            isMulti
            value={this.state.selected_aois}
            options={options}
            onChange={(selected) => {
              this.setState({ selected_aois: selected });
            }}
            isOptionDisabled={(option) => option.disabled}
          />
        </Form.Group>
        <Form.Group as={Col} className="4">
          <Form.Label>Region</Form.Label>
          <Form.Select
            value={this.state.region}
            onChange={(e) => this.handleChange(e, "region")}
          >
            {REGIONS.map((region) => (
              <option value={region}>{region}</option>
            ))}
          </Form.Select>
        </Form.Group>
      </Row>
    );
  }

  addICPathOption = (option) => {
    this.setState({
      IC_path_options: [
        ...this.state.IC_path_options,
        { value: option, label: option },
      ],
    });
  };

  render() {
    return (
      <>
        <Modal
          size="lg"
          animation={false}
          show={this.state.showModal}
          onHide={this.handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.edit ? <>Edit script</> : <>Upload script</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.error !== "" && (
              <Alert variant="danger" transition={false}>
                {this.state.error}
              </Alert>
            )}
            {this.state.loading ? (
              LoadingIcon()
            ) : (
              <Tabs forceRenderTabPanel>
                <TabList>
                  <Tab>General</Tab>
                  <Tab>Execution Times</Tab>
                </TabList>
                <TabPanel>
                  <Form>
                    {this.renderAois()}
                    <Row className="mb-3">
                      <Form.Group as={Col} className="4">
                        <Form.Label>Alias</Form.Label>
                        <Form.Control
                          class="form-control form-control-sm"
                          type="text"
                          disabled={this.state.loading}
                          value={this.state.alias}
                          onChange={(e) => this.handleChange(e, "alias")}
                          isValid={this.state.alias !== ""}
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="4">
                        <Form.Label>Python script name</Form.Label>
                        <Form.Control
                          class="form-control form-control-sm"
                          type="text"
                          disabled={true}
                          value={this.state.script_name}
                          onChange={(e) => this.handleChange(e, "script_name")}
                          isValid={this.state.script_name !== ""}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} className="4">
                        <Form.Label>
                          Input Image Collection - Project name
                        </Form.Label>
                        <Form.Select
                          value={this.state.input_project_id}
                          onChange={(e) =>
                            this.handleChange(e, "input_project_id")
                          }
                        >
                          {PROJECT_IDS.concat("").map((projectId) => (
                            <option value={projectId}>{projectId}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group as={Col} className="4">
                        <Form.Label>
                          Output Image Collection Project name
                        </Form.Label>
                        <Form.Select
                          value={this.state.output_project_id}
                          onChange={(e) =>
                            this.handleChange(e, "output_project_id")
                          }
                        >
                          {PROJECT_IDS.concat("").map((projectId) => (
                            <option value={projectId}>{projectId}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} className="4">
                        <Form.Label>Input Image Collection</Form.Label>
                        <CreatableSelect
                          options={this.state.IC_path_options}
                          value={this.state.input_image_collection}
                          disabled={this.state.loading}
                          onCreateOption={this.addICPathOption}
                          onChange={(selection) =>
                            this.setState({ input_image_collection: selection })
                          }
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="4">
                        <Form.Label>Output Image Collection name</Form.Label>
                        <Form.Control
                          class="form-control form-control-sm"
                          type="text"
                          disabled={this.state.loading}
                          value={this.state.output_image_collection}
                          onChange={(e) =>
                            this.handleChange(e, "output_image_collection")
                          }
                          isValid={this.state.output_image_collection !== ""}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} className="4">
                        <Form.Label>Output Image Collection name</Form.Label>
                        <Form.Control
                          class="form-control form-control-sm"
                          type="text"
                          disabled={this.state.loading}
                          value={this.state.output_image_collection}
                          onChange={(e) =>
                            this.handleChange(e, "output_image_collection")
                          }
                          isValid={this.state.output_image_collection !== ""}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} className="4">
                        <Form.Label>Upload the .shp-file</Form.Label>
                        <Form.Control
                          class="form-control form-control-sm"
                          type="file"
                          disabled={this.state.loading}
                          accept=".shp"
                          onChange={(e) =>
                            this.handleFileChange(e, "shapefile")
                          }
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="4">
                        <Form.Label>Upload the .shx-file</Form.Label>
                        <Form.Control
                          class="form-control form-control-sm"
                          type="file"
                          disabled={this.state.loading}
                          accept=".shx"
                          onChange={(e) =>
                            this.handleFileChange(e, "shapefile_shx")
                          }
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="4">
                        <Form.Label>Upload the .pkl-file</Form.Label>
                        <Form.Control
                          class="form-control form-control-sm"
                          type="file"
                          disabled={this.state.loading}
                          accept=".pkl"
                          onChange={(e) => this.handleFileChange(e, "pkl_file")}
                        />
                      </Form.Group>
                    </Row>
                  </Form>
                </TabPanel>
                <TabPanel>
                  <Form>
                    <Row className="mb-3">
                      <Form.Group as={Col} className="10">
                        <Select
                          value={{
                            label: this.state.execution_times.timezone,
                            value: this.state.execution_times.timezone,
                          }}
                          options={moment.tz.names().map((timezone) => {
                            return { label: timezone, value: timezone };
                          })}
                          onChange={(timezone) => {
                            let execution_times = this.state.execution_times;
                            execution_times.timezone = timezone.value;
                            this.setState({ execution_times });
                          }}
                        />
                      </Form.Group>
                      <Form.Text muted>
                        The timezone can be changed here, default is UTC.
                      </Form.Text>
                    </Row>
                    {this.renderDays()}
                  </Form>
                </TabPanel>
              </Tabs>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div class="script-upload-footer">
              <button
                className="btn btn-dark mr-3"
                type="button"
                onClick={() => this.handleSubmit()}
              >
                Upload
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
